/* Global Styles */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~bootstrap/dist/css/bootstrap.min.css';

* {
  color: #414450;
  font-family: IBM Plex Sans, sans-serif !important;
}

.page {
  height: 100%;
  overflow-y: auto;
}

.btn-login {
  background: #009dd6;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.login-background {
  background: linear-gradient(
      90deg,
      rgba(243, 231, 233, 0.35) 0%,
      rgba(227, 238, 255, 0.35) 99%,
      rgba(227, 238, 255, 0.35) 100%
    ),
    linear-gradient(0deg, #fffcfc, #fffcfc);
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: local('IBM Plex Sans'),
    url(./assets/fonts/IBM-Plex-Sans/IBMPlexSans-Text.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: local('IBM Plex Sans'),
    url(./assets/fonts/IBM-Plex-Sans/IBMPlexSans-SemiBold.ttf) format('truetype');
  font-weight: 550;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: local('IBM Plex Sans'),
    url(./assets/fonts/IBM-Plex-Sans/IBMPlexSans-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/MaterialIcons-Regular.ttf); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(./assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
